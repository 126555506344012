.auditing {
  &__body {
    padding-top: 16px;
    &__card {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      background: white;
      padding: 40px 32px;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      .ant-tabs {
        width: 100%;
      }
      &--activities {
        padding-left: 24px;
        &__title {
          font-weight: 600;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        .ant-tabs-nav-wrap {
          display: flex;
          justify-content: center;
          border-bottom: 1px solid #f0f0f0;
        }
      }
      &__sub-container {
        align-self: center;
        display: flex;
        width: 80%;
      }

      &__title {
        margin-bottom: 0;
        text-align: center;
        font-size: 24px;
      }
    }
  }
}
