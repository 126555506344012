.packings {
  &__body {
    padding-top: 16px;
    padding-right: 16px;

    &__card-table {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      background: white;
      padding: 40px 32px;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      &__title {
        margin-bottom: 0;
        text-align: center;
        font-size: 24px;
      }

      &__subtitle {
        margin-bottom: 0;
        font-size: 20px;
      }
    }
  }
}
