.footer {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 50px;
  &__company-name {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 10px;
  }
}
