.packing-resume {
    display: flex;
    flex-direction: column;

    &__card {
        display: flex;
        padding: 24px 16px;
        border: 1px solid #BFBFBF;
        background: #F5F5F5;
        min-height: 500px;

        &__label {
            padding-top: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 14px;
            color: #389E0D;
            width: 24px;
            border: 1px solid #C6FFAE;
            background-color: #D7FFC6;
            margin-right: 24px;
        }
    }
}