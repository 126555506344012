.login {
  &__container {
    background-image: url("./../../assets/images/bg.png"); /* Adiciona a imagem de fundo */
    background-size: cover; /* Ajusta a imagem para cobrir todo o fundo */
    background-repeat: no-repeat; /* Evita que a imagem se repita */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    &__title {
      text-align: center;
      margin-bottom: 12px;
    }

    &__description {
      text-align: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.tab__container {
  display: flex;
  flex-direction: column;

  &__remember-me {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 22px;

    &__forgot-password {
      margin-left: auto;
    }
  }

  &__login-button {
    width: 115px;
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-top: 22px;

    &__enter-link {
      margin-left: auto;
    }
  }
}
