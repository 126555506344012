.navbar-vendas {
    &__card {
        display: flex;
        align-items: center;
        background: white;
        padding: 7px 16px;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
        &__right-nav {
            display: flex;
            align-items: center;
            margin-left: auto;
            &__name {
                margin-top: unset;
                font-weight: 400;
                line-height: 22px;
                margin-bottom: unset;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                margin-right: 24px;
            }
        }
    }
}