body {
  margin: 0;
  font-family: 'Selawik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(240, 242, 245, 1);
  color: rgba(20, 20, 20, 1);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 200px;
}

input[type="checkbox" i] {
  background-color: red;
  border-color: red;
}

h5 {
  font-weight: 600;
  font-size: 16px;
  color: rgba(52, 64, 84, 1);
}

h3 {
  font-size: 24px;
  color: #344054;
  font-weight: 600;
}

h4 {
  font-size: 20px;
  font-weight: 600;
}

.text-primary {
  color: rgba(56, 158, 13, 1)
}

/* regular */
@font-face {
  font-family: 'Selawik';
  src: url('./assets/fonts/selawik/selawik.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* bold */
@font-face {
  font-family: 'Selawik';
  src: url('./assets/fonts/selawik/selawikb.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* semi bold */
@font-face {
  font-family: 'Selawik';
  src: url('./assets/fonts/selawik/selawiksb.ttf') format('truetype');
  font-weight: semi-bold;
  font-style: normal;
}

.highcharts-credits {
  display: none;
}