.drawer {
  &__title {
    font-weight: 600;
    font-size: 24px;
    margin-top: 42px;
    text-align: center;
  }

  &__menu {
    margin-top: 80px;
    &__item {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      color: #000000d9;
      &__icon {
        margin-right: 13px;
      }

      &.active {
        background-color: #d7ffc6;
        color: #389e0d;
        border-right: 3px solid rgba(56, 158, 13, 1);
      }

      &__subitems {
        width: 100%;
        color: inherit;
        &__subitem {
          display: flex;
          width: 100%;
          height: 40px;
          padding-left: 44px;
          &.active {
            background-color: #d7ffc6;
            color: #389e0d;
            border-right: 3px solid rgba(56, 158, 13, 1);
          }
          &.disabled {
            color: #00000040;
          }

          p {
            cursor: pointer;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
}
.outbound {
  &__body {
    padding-top: 16px;
    padding-right: 16px;

    &__card-table {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      background: white;
      padding: 40px 32px;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      &__title {
        margin-bottom: 0;
        text-align: center;
        font-size: 24px;
      }

      &__subtitle {
        margin-right: auto;
        margin-bottom: 0;
        font-size: 20px;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(56, 158, 13, 1);
    border-color: rgba(56, 158, 13, 1);
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: rgba(56, 158, 13, 1);
  }

  .ant-checkbox-input:hover .ant-checkbox-inner {
    border-color: rgba(56, 158, 13, 1);
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: rgba(56, 158, 13, 1);
  }
}
