.dashboard {
  &__body {
    padding-top: 16px;
    padding-right: 16px;

    &__card-table {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      background: white;
      padding: 40px 32px;
      border: 1px solid #f0f0f0;
      border-radius: 2px;

      &__title {
        margin-bottom: 0;
        text-align: left;
        font-size: 24px;
      }

      &__header {
        margin-top: 24px;
        display: flex;

        .ant-avatar {
          width: 72px;
          height: 72px;
        }

        &__greetings {
          display: flex;
          align-items: flex-end;
          margin-right: auto;
        }

        &__metrics {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          &__value {
            text-align: right;
            margin-top: 12px;
            font-size: 24px;
            color: rgba(56, 158, 13, 1);
          }
        }
      }
    }

    &__card-graph {
      margin-top: 24px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      background-color: rgba(255, 255, 255, 1);
      padding: 24px;

      &__granularity {
        position: relative;
        cursor: pointer;
        font-weight: 400;
        margin-right: 24px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.7);
        z-index: 100;
        &--selected {
          color: rgba(56, 158, 13, 1);
        }
      }
    }
  }
}
