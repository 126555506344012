.packing {
  &__body {
    padding-top: 16px;
    padding-right: 16px;

    &__card-table {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      background: white;
      padding: 40px 32px;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      &__title {
        display: flex;
        align-items: center;
        align-self: center;
        margin-bottom: 0;
        font-size: 24px;
      }

      .ant-tabs-nav-wrap {
        justify-content: center;
      }
    }
  }

  &__seal {
    border: 1px solid rgba(217, 217, 217, 1);
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 600;
    padding: 6px 8px;
    text-align: center;
    border-radius: 2px;
    align-self: center;
    margin-top: 20px;
  }

  &__form {
    &__title {
      margin-bottom: 24px;
    }
  }
}
